import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import Layout from "../components/Layout/layout"
import SEO from "../components/SEO/seo"
import Table from "../components/Table/table"
import StartOverButton from "../components/StartOverButton/StartOverButton"

import "./index.scss"

import { INDUSTRY_TABLE } from "../types/types"

export default class IndustryTable extends React.Component<{}> {
  render() {
    return (
      <Layout>
        <SEO title="Industry Table" />
        <StartOverButton />
        <Table tableType={INDUSTRY_TABLE} />
      </Layout>
    )
  }
}
